import * as React from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import css from './AddToCartButtonPrice.scss';
import { formatMoney } from 'utils/money';
import { Button } from 'styleguide/components';
import { ErrorMessage } from 'styleguide/components/forms';
import Loader from 'styleguide/components/Loader/Loader';
import QuoteContext from 'contexts/QuoteContext/QuoteContext';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import { useFormikContext } from 'formik';
import { KeyVal } from 'libs/utils/common-types';
import { submitQuoteRequest } from 'api/persephone/persephone';
import OrderContext from 'contexts/OrderContext/OrderContext';
import UserContext from 'contexts/UserContextContainer/UserContext';
import merge from 'lodash-es/merge';
import { clickRefreshQuote } from 'api/gtm';

interface Props {
  showDiscountMessage: boolean;
}

const AddToCartButtonPrice = ({ showDiscountMessage }: Props) => {
  const { order } = React.useContext(OrderContext);
  const { currentUser } = React.useContext(UserContext);
  const quoteContext = React.useContext(QuoteContext);
  const location = useLocation();
  const { values, isValid } = useFormikContext<KeyVal>();
  const { price, onSubmit, discountPrice, discountMessage } = quoteContext;
  const isLoading =
    quoteContext.status === 'addingToCart' ||
    quoteContext.status === 'quoting' ||
    (price === null && quoteContext.status !== 'quoteError' && quoteContext.instantQuote);

  return (
    <div className="mt-0.5 flex flex-col space-y-0.5 md:mr-4 md:mt-1.5 mx-2 md:ml-0 grow md:grow-0">
      {showDiscountMessage && discountMessage ? (
        <HtmlContent className="self-center !text-xs md:self-end lg:!text-base" content={discountMessage} />
      ) : (
        <div className="before:block">&nbsp;</div>
      )}

      <div className="flex flex-row items-center justify-between md:justify-end md:space-x-4">
        {isLoading ? (
          <Loader className="mr-6" />
        ) : (
          <div className="">
            {discountPrice ? (
              <>
                <span className={cn(css.priceInherit, css.price, css.priceStrikethrough)}>
                  {formatMoney(price)}
                </span>
                <span className={cn(css.priceInherit, css.price, css.priceDiscounted)}>
                  {/* ADNAN: discount max value 500. move hardcode to CMS? */}
                  {formatMoney(discountPrice)}
                </span>
              </>
            ) : (
              <span className="bold whitespace-nowrap font-hvBold text-xl md:text-3xl/7">
                {formatMoney(price)}
              </span>
            )}
          </div>
        )}

        {['quoteError', 'minRequirementsError'].includes(quoteContext.status) && (
          <ErrorMessage className="self-center md:hidden" message={quoteContext.errorMessage} />
        )}
        {quoteContext.instantQuote || (!quoteContext.instantQuote && quoteContext.price !== null) ? (
          <>
            <Button
              size="xs"
              className="!bold !px-0.5 !font-hvBold md:!hidden"
              disabled={
                price === null ||
                quoteContext.status === 'quoteError' ||
                quoteContext.status === 'minRequirementsError' ||
                isLoading ||
                quoteContext.status === 'customQuote'
              }
              onClick={() => {
                onSubmit(merge(values, { product: quoteContext.product.getProductKey() }));
              }}
              type="button"
              color="orange"
            >
              {isLoading ? <Loader size="xs" /> : 'Add to cart'}
            </Button>
            <Button
              size="sm"
              dataCy="addToCartButton"
              className="!bold !px-0.5 !font-hvBold !hidden md:!inline-flex"
              disabled={
                price === null ||
                quoteContext.status === 'quoteError' ||
                quoteContext.status === 'minRequirementsError' ||
                isLoading ||
                quoteContext.status === 'customQuote'
              }
              onClick={() => {
                onSubmit(merge(values, { product: quoteContext.product.getProductKey() }));
              }}
              type="button"
              color="orange"
            >
              {isLoading ? <Loader size="xs" /> : 'Add to cart'}
            </Button>
          </>
        ) : (
          <>
            <Button
              className="!bold !px-0.5 !font-hvBold md:!hidden"
              size="xs"
              disabled={isLoading || !isValid}
              onClick={() => {
                quoteContext.onChange(quoteContext.initialValues, values, true).then(res => {
                  submitQuoteRequest(
                    order.number,
                    currentUser ? currentUser.id : null,
                    quoteContext.product.getProductKey(),
                    values,
                    location.pathname,
                    res,
                  ).then(() => {
                    clickRefreshQuote({
                      location: 'sticky_nav',
                      ...values,
                      error: quoteContext.errorMessage,
                    });
                  });
                });
              }}
              type="button"
              color="blue"
            >
              Refresh Quote
            </Button>
            <Button
              className="!bold !px-0.5 !font-hvBold !hidden md:!inline-flex"
              size="sm"
              disabled={isLoading || !isValid}
              onClick={() => {
                quoteContext.onChange(quoteContext.initialValues, values, true).then(res => {
                  submitQuoteRequest(
                    order.number,
                    currentUser ? currentUser.id : null,
                    quoteContext.product.getProductKey(),
                    values,
                    location.pathname,
                    res,
                  ).then(() => {
                    clickRefreshQuote({
                      location: 'sticky_nav',
                      ...values,
                      error: quoteContext.errorMessage,
                    });
                  });
                });
              }}
              type="button"
              color="blue"
            >
              Refresh Quote
            </Button>
          </>
        )}
      </div>
      {['quoteError', 'minRequirementsError'].includes(quoteContext.status) && (
        <ErrorMessage
          className="self-center text-center md:self-end hidden md:block"
          message={quoteContext.errorMessage}
        />
      )}
    </div>
  );
};

export default AddToCartButtonPrice;
