import * as React from 'react';
import { contactPhone } from 'app/constants';
import NeedAssistance from './NeedAssistance/NeedAssistance';
import RushDeliveryAvailable from './RushDeliveryAvailable/RushDeliveryAvailable';
import WorriedAboutDesign from './WorriedAboutDesign/WorriedAboutDesign';
import AddToCartButtonPrice from './AddToCartButtonPrice/AddToCartButtonPrice';
import { RushDeliveryData } from '../ProductDetailsPage';
import QuoteContext from 'contexts/QuoteContext/QuoteContext';
import Loader from 'styleguide/components/Loader/Loader';
import A from 'styleguide/components/Links/A';

interface Props {
  rushDeliveryData: RushDeliveryData | null;
  informational?: boolean;
}

const AddToCart = ({ rushDeliveryData, informational }: Props) => {
  const minDate = rushDeliveryData === null ? null : rushDeliveryData.minDate;
  const seconds = rushDeliveryData === null ? 0 : rushDeliveryData.seconds;
  const quoteContext = React.useContext(QuoteContext);
  const isLoading =
    quoteContext.status === 'addingToCart' ||
    quoteContext.status === 'quoting' ||
    (quoteContext.price === null && quoteContext.status !== 'quoteError' && quoteContext.instantQuote);

  return (
    //  positioning and z-index needed here for nav bar overlay to work properly
    <div className="relative z-[10] mx-auto flex justify-center">
      <div
        className="fixed bottom-0 top-auto z-100 mx-auto flex h-20 items-center rounded-lg border-t border-solid border-gray-50 bg-white shadow-xl
      md:h-[9rem] md:justify-between lg:inset-x-8 lg:bottom-4 lg:h-28 lg:max-w-base lg:border -lg:w-full"
      >
        {informational ? (
          <div className="my-2 flex flex-col items-center justify-center text-center">
            <div className="font-hvBold text-sm leading-relaxed md:text-base lg:text-lg">
              Interested in this product?
            </div>
            <div className="text-sm leading-normal lg:text-base">
              Call{' '}
              <A className="!text-sm lg:!text-base" href="tel:1-877-649-5463" underline="always" color="blue">
                1-877-649-5463
              </A>{' '}
              or email{' '}
              <A
                className="!text-sm lg:!text-base"
                href="mailto: service@printivity.com"
                underline="always"
                color="blue"
              >
                service@printivity.com
              </A>{' '}
              for a custom quote.
            </div>
          </div>
        ) : (
          <div className="flex w-full flex-col">
            <div className="flex w-full grow flex-row justify-center self-start md:justify-between">
              <div className="ml-1 mt-4 flex-row md:ml-2 hidden md:flex">
                <NeedAssistance contactPhone={contactPhone} emailUsUrl="/contact-us" />
                <div className="ml-1 md:ml-2">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <RushDeliveryAvailable
                      date={minDate}
                      hidden={rushDeliveryData === null || quoteContext.status === 'customQuote'}
                      durationSeconds={seconds}
                    />
                  )}
                </div>
                <WorriedAboutDesign />
              </div>
              <AddToCartButtonPrice showDiscountMessage={false} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddToCart;
