import { format } from 'date-fns-tz';

export const getDurationString = totalSeconds => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  return `${hours} hr${hours > 0 ? 's' : ''} ${minutes} min${minutes > 0 ? 's' : ''}`;
};

export const weekdayAndDate = date => format(new Date(date), 'EEEE, MMMM do');

export const displayDateWithTimezone = (dateString: string, type: 'short' | 'long' = 'long'): string => {
  let field: string;
  if (type === 'long') {
    field = format(new Date(dateString), 'MMMM do, yyyy h:mma zzz');
  }
  if (type === 'short') {
    field = format(new Date(dateString), 'MM/d h:mma zzz');
  }
  return field;
};
